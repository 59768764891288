import applePay from 'braintree-web/apple-pay'
import { createClient } from '@/util/braintree.js'

import Tracker from '@/util/tracker.js'

export async function create(container, options) {
  const { authorization, totalPrice, currency, lineItems } = options

  const available =
    window.ApplePaySession &&
    window.ApplePaySession.supportsVersion(3) &&
    window.ApplePaySession.canMakePayments()
  if (!available) {
    console.log('Apple Pay is unavailable')
    return null
  }

  const instance = await applePay.create({
    client: await createClient({ authorization }),
  })
  // const canMakePayments =
  //   await window.ApplePaySession.canMakePaymentsWithActiveCard(
  //     instance.merchantIdentifier,
  //   )
  // if (!canMakePayments) {
  //   console.log('Apple Pay is available but active card is not')
  //   return null
  // }

  container.classList.add('cursor-pointer')
  container.style.appearance = '-apple-pay-button'
  container.style['-apple-pay-button-type'] = 'plain'
  container.style['-apple-pay-button-style'] = 'black'
  container.setAttribute('role', 'button')

  container.addEventListener('click', async function (_ev) {
    const paymentRequest = instance.createPaymentRequest({
      total: {
        type: 'pending',
        label: 'FIXD Automotive',
        amount: totalPrice,
      },
      currencyCode: currency,
      lineItems,
      requiredBillingContactFields: ['postalAddress'],
      requiredShippingContactFields: ['postalAddress', 'name', 'email'],
    })

    const session = new window.ApplePaySession(3, paymentRequest)

    session.onvalidatemerchant = async function (ev) {
      try {
        const msession = await instance.performValidation({
          validationURL: ev.validationURL,
          displayName: 'Upsellery',
        })
        await session.completeMerchantValidation(msession)
      } catch (err) {
        console.error('onvalidatemerchant', err)
        session.abort()
      }
    }

    session.onpaymentauthorized = async function (ev) {
      try {
        const tokenized = await instance.tokenize({
          token: ev.payment.token,
        })

        container.dispatchEvent(
          new CustomEvent('applePayPaymentAuthorized', {
            bubbles: true,
            detail: {
              applePay: instance,
              paymentData: {
                ...tokenized,
                details: {
                  ...tokenized.details,
                  rawPaymentData: ev.payment,
                },
              },
            },
          }),
        )

        session.completePayment(window.ApplePaySession.STATUS_SUCCESS)
      } catch (err) {
        console.error('onpaymentauthorized', err)
        try {
          session.completePayment(window.ApplePaySession.STATUS_FAILURE)
        } catch (err2) {
          console.error(err2)
        }
      }
    }

    session.begin()
  })

  return instance
}

class ApplePayButton extends HTMLElement {
  get paymentMethodName() {
    return 'Apple Pay'
  }

  async connectedCallback() {
    if (this.once) {
      return
    }
    this.once = true

    this.abort = new AbortController()
    const { signal } = this.abort

    this.applePay = await create(this, {
      authorization: this.getAttribute('authorization'),
      totalPrice: this.getAttribute('totalprice'),
      currency: this.getAttribute('currency'),
      lineItems: JSON.parse(this.getAttribute('lineitems')),
    })
    if (this.applePay == null) {
      this.classList.add('hidden')
      return
    }

    const location = this.getAttribute('page')

    await Tracker.track('express button loaded', {
      type: 'apple pay',
      location,
    })
    ;[
      ['click', 'clicked'],
      ['applePayPaymentAuthorized', 'approved'],
    ].forEach(([event, name]) => {
      this.addEventListener(
        event,
        async () => {
          await Tracker.track(`express button ${name}`, {
            type: 'apple pay',
            location,
          })
        },
        { signal },
      )
    })
  }

  disconnectedCallback() {
    this.abort.abort()
  }
}

customElements.define('applepay-button', ApplePayButton)
