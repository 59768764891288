import client from 'braintree-web/client'

const clients = new Map()

/**
	@param {{authorization: string}} options
	@returns {Promise<client.Client>}
	*/
export async function createClient(options) {
  const { authorization } = options
  const existing = clients.get(authorization)
  if (existing != null) {
    return existing
  }

  const c = client.create({ authorization })
  clients.set(authorization, c)
  return await c
}
