import paypalCheckout from 'braintree-web/paypal-checkout'
import { createClient } from '@/util/braintree.js'

import Tracker from '@/util/tracker.js'

/**
 * @param {HTMLElement} container
 * @param {{authorization: string, style: string}} options
 * @returns {Promise<paypalCheckout.PayPalCheckout>}
 */
export async function create(container, options) {
  const { authorization, radius } = options

  const instance = await paypalCheckout.create({
    client: await createClient({ authorization }),
  })
  await instance.loadPayPalSDK({ vault: true })

  const styleOptions = {
    height: 50,
    ...(radius != null ? { borderRadius: parseInt(radius, 10) } : {}),
  }

  // Why is PayPal's API so atrocious?
  // And their documentation as well?
  window.paypal
    .Buttons({
      style: styleOptions,
      fundingSource: window.paypal.FUNDING.PAYPAL,

      createBillingAgreement() {
        return instance.createPayment({
          flow: 'vault',
          enableShippingAddress: true,
        })
      },

      onInit(_data, _actions) {
        container.dispatchEvent(
          new CustomEvent('paypalInit', {
            bubbles: true,
          }),
        )
      },

      onClick(_data, _actions) {
        container.dispatchEvent(
          new CustomEvent('paypalClick', {
            bubbles: true,
          }),
        )
      },

      async onApprove(data, _actions) {
        const payload = await instance.tokenizePayment(data)
        container.dispatchEvent(
          new CustomEvent('paypalApprove', {
            bubbles: true,
            detail: {
              paypal: instance,
              paymentData: payload,
            },
          }),
        )
        return payload
      },

      onError(err) {
        throw err
      },
    })
    .render(container)

  return instance
}

class PayPalButton extends HTMLElement {
  get paymentMethodName() {
    return 'PayPal'
  }

  async connectedCallback() {
    if (this.once) {
      return
    }
    this.once = true

    this.abort = new AbortController()
    const { signal } = this.abort

    this.paypalCheckout = await create(this, {
      authorization: this.getAttribute('authorization'),
      radius: this.getAttribute('radius'),
    })
    if (this.paypalCheckout == null) {
      this.classList.add('hidden')
    }

    const location = this.getAttribute('page')

    ;[
      ['paypalInit', 'loaded'],
      ['paypalClick', 'clicked'],
      ['paypalApprove', 'approved'],
    ].forEach(([event, name]) => {
      this.addEventListener(
        event,
        async () => {
          await Tracker.track(`express button ${name}`, {
            type: 'paypal',
            location,
          })
        },
        { signal },
      )
    })
  }

  disconnectedCallback() {
    this.abort.abort()
  }
}

customElements.define('paypal-button', PayPalButton)
